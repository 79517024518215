import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { JsonToTable } from 'react-json-to-table';
import './documentViewer.css';

const DocumentViewer = ({ document, metaData, id, docHash, onClose }) => {
  const [showMetadata, setShowMetadata] = useState(false);
  const [showVc, setShowVc] = useState(false);
  const [showDocHash, setShowDocHash] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [vcData, setVcData] = useState([]);

  console.log('iD', id);

  useEffect(() => {
    getVcData();
  }, []);


  const isImage = (document) => {
    // Check if the URL contains "image" as a substring
    if (document.includes('images')) {
      return true;
    }
  
    // Add more keywords or patterns to check for PDF or other formats
    // if (document.includes('pdf')) {
    //   return false; // Assume it's not an image
    // }
  
    // Default to assuming it's not an image
    return false;
  };

  const isImageFile =  isImage(document);
console.log('Is it an image?', isImageFile);

  const getVcData = async () => {
    try {
      const fetchVcResponse = await fetch(`https://auditdb.myearth.id/api/vcnew/${id}/associated-vc`);
      const responseData = await fetchVcResponse.json();
      console.log('Response', responseData);
      console.log(responseData.vc);
      setVcData(responseData.vc);
    } catch {
      console.log('Error fetching VC for this doc');
    }
  };

  const toggleView = () => {
    setShowTable((prevShowTable) => !prevShowTable);
  };

  const showMetadataSection = () => {
    setShowMetadata(true);
    setShowVc(false);
    setShowDocHash(false);
  };

  const showVcSection = () => {
    setShowMetadata(false);
    setShowVc(true);
    setShowDocHash(false);
  };

  const showDocHashSection = () => {
    setShowMetadata(false);
    setShowVc(false);
    setShowDocHash(true);
  };

  return (
    <div className="document-popup">
      <div className="popup-content">
        <div className="close-button-container">
          <FontAwesomeIcon className="close-button" onClick={onClose} icon={faClose} />
        </div>
      
        {/* <h3>Preview:</h3> */}
        <div className="apprrejectDiv">
          <button className="approve-button">Approve</button>
          <button className="reject-button">Reject</button>
        </div>

        {isImageFile ? (
        
        <img src={document} alt="Document" />
     ) : (
       <Document file={document}>
       <Page pageNumber={1} width={500} height={400} />
     </Document>
     )}

        <div className="section-buttons">
          <button className="metadata-button" onClick={showMetadataSection}>
            Metadata
          </button>
          <button className="metadata-button" onClick={showVcSection}>
            Digital Proof
          </button>
          <button className="metadata-button" onClick={showDocHashSection}>
             Document Hash
          </button>
        </div>

        {showMetadata && (
          <div className="metadata-section">
            <h4>Metadata:</h4>
            <button className="chgview-button" onClick={toggleView}>
              Change View
            </button>
            {showTable ? (
              <pre>{JSON.stringify(metaData, null, 2)}</pre>
            ) : (
              <pre>
                <JsonToTable json={metaData} />
              </pre>
            )}
          </div>
        )}

        {showVc && (
          <div className="metadata-section">
            <h4>Digital Proof:</h4>
            <button className="chgview-button" onClick={toggleView}>
              Change View
            </button>
            {showTable ? (
              <pre>{JSON.stringify(vcData, null, 2)}</pre>
            ) : (
              <pre>
                <JsonToTable json={vcData} />
              </pre>
            )}
          </div>
        )}

        {showDocHash && (
          <div className="metadata-section">
            <h4>Document Hash:</h4>
            <pre>{docHash}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentViewer;
