import React, { useState, useEffect, useRef } from 'react';
import './idcertificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import studentPhoto from '../../img/studentIcon.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:2utZGEuzWe8ds5Jaxd7KdMD7bwKMm8QiRKnS2t4Q8Dve;earthid:testnet:fid=0.0.15057499";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";

const IDCertificate = () => {
    const [responseData, setResponseData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    //const [showJson, setShowJson] = useState(true);
    const { userName } = useParams();

    const pdfContainerRef = useRef();


    useEffect(() => {

      
        const fetchData = async () => {
            try {
                const prefix = 'IDCertificate'; // Replace with your desired prefix
            const userNamee = `${prefix}${userName}`;
            console.log('Username', userNamee)
                const cidsResponse = await fetch(`https://auditdb.myearth.id/api/vc/fetchVcCids/${userNamee}`);
                const cidJson = await cidsResponse.json();
                console.log('CidsResponse', cidJson);

                if (cidJson && cidJson.cids && cidJson.cids.length > 0) {
                    const response = await fetch('https://auditdb.myearth.id/api/vc/fetchVCMain', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/vnd.ipld.car',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            cids: [
                                cidJson.cids[cidJson.cids.length - 1]
                            ],
                        }),
                    });

                    if (response.ok) {
                        const json = await response.json();
                        console.log(json);
                        setResponseData(json);
                    } else {
                        console.log('Request failed with status code:', response.status);
                    }
                } else {
                    console.log('Certificate not generated');
                    Swal.fire('Certificate Not Generated');
                }
            } catch (error) {
                console.log('Error:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);





    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                    
                    "EarthID Universty",
                    userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                        
                        "EarthID Universty",
                        userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                            
                            "EarthID Universty",
                            userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                
                                "EarthID Universty",
                                userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                    
                                    "EarthID Universty",
                                    userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A'),
                                        
                                        "EarthID Universty",
                                        userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 10000);
        }
    }, [isButtonClicked, responseData]);

    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
            console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    const verifyVC = async () => {
        try {

            const signature = await createUserSignature();

            const data = {
                "credentials": {
                    ...responseData[0]
                }
            }
            const config = {
                method: 'post',
                url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
                headers: {
                    'X-API-KEY': authorizationKey,
                    'publicKey': publicKey,
                    'userSignature': signature, // Call the createUserSignature function
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            };

            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsVerifying(true);
        setIsButtonClicked(true);

        // Call verifyVC() here
        verifyVC().then((verificationResult) => {
            // Process the verification result and update state variables accordingly
            setIsVerifying(false);
            // Update other state variables based on verification result
            // Example:
            // setIsVerificationLoading([...]);
            // setVerificationData([...]);
        }).catch((error) => {
            // Handle error
            setIsVerifying(false);
            // Display error message or take appropriate action
        });
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,     
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };




   // Function to handle the PDF download
   const handleDownload = () => {
    const pdfContainer = pdfContainerRef.current;

    // Create a new jsPDF instance with the A4 landscape dimensions
    const pdf = new jsPDF('p', 'pt', [560, 350]);

    // Use html2canvas to render the content of the container as an image
    html2canvas(pdfContainer, { scale: 3, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 0, 0, 350, 560);
        pdf.save(`studentID-(${userName}).pdf`);
        // Open the PDF in a new window
        //   const pdfWindow = window.open('', '_blank');
        //   pdfWindow.document.write('<iframe width="100%" height="100%" src="' + pdf.output('datauristring') + '"></iframe>');
    });
};


    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a href={`/certificate/on-boarding/${userName}`}>Home</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-containerr">
                    <section className='certBackk'>
  <div ref={pdfContainerRef} className="certificatee">
    <div className="bodbox">
      <div className="header">
        <div className="favico">
          <img src={favicon} alt="Logo" />
        </div>
        <div className="title">Student ID Card</div>
      </div>
      <div className="body">
        <div className="photo">
          <img src={studentPhoto} alt="Student Photo" />
        </div>
        <div className="details">
          <div className="name">{userName}</div>
          <div className="student-id"><span>ID:</span> 202300145</div>
          <div className="department"><span>Department:</span> Computer Science & Engineering</div>
          <div className="validity"><span>Valid till:</span> {moment(responseData[0].expirationDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
        </div>
      </div>
      <div className="footer">
        <div className='university-logo'>
          <img src={logo} alt="University Logo" />
        </div>
      </div>
    </div>
  </div>
</section>

                    </div>
                    <section className='infosec'>
                        <div className='container'>
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <h2 className='coursename col-md-11'>Student ID Certificate</h2>
                                    </div>

                                    <div className='stuname'>Issued to: {userName}</div>
                                    <div className='stuID'>ID: EarthID</div>
                                    <button className='pdfbtn' onClick={handleDownload}>Download PDF</button>
                                    <hr></hr>
                                    <div className='description'>
                                    This certificate proofs {userName} is a registered student of EarthID University, and is hereby granted an official Student ID.
                                     This ID certifies the student's affiliation with the university and serves as proof of their enrollment in the mentioned program.
                                    </div>
                                    <hr></hr>
                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{moment(responseData[0].issuanceDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            <div>{moment(responseData[0].expirationDate).format('MMMM Do YYYY, h:mm:ss A')}</div>
                                        </div>
                                    </div>
                                    <button className='verify-btnn' onClick={handleViewCertificate}>View Digital Certificate</button>
                                </div>
                                <div className='col-md-4'>
                                    <div className="footer1">
                                        <div className='verify'>Certificate Verification</div>
                                        <div className='verify1'>This certificate is from a verified issuer</div>
                                        <button className="verify-btn" onClick={handlePopupOpen}>
                                            Verify
                                        </button>
                                    </div>
                                    <div className="footer1">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>EarthID University</div>
                                        <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>

                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            X
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issuer is Valid:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issuer is Valid:</div>

                                                    <div className="verification-result1">
                                                        <div>The issuer is found to be valid</div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued on:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued on:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[0] && <div>{verificationData[0]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued by:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued by:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[1] && <div>{verificationData[1]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>

                                                    <div className="verification-result1">
                                                        {verificationData[2] && <div>{verificationData[2]}</div>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label"></div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result1">
                                                        <div className='verified'>VERIFIED</div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <footer className="footer">
                        <div className="logo"></div>
                        <div className="footer-text">
                            © 2023 EarthID, Inc. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default IDCertificate;
